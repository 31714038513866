import React from "react"
import { graphql } from "gatsby"
import InnerPageLayout from '../../layout/inner-page'
import { Container, Row, Col } from '../../../../shared-ui/components/ui/wrapper'
import SectionTitle from '../../../../shared-ui/components/ui/section-title'
import ServiceBox from '../../../../shared-ui/components/box-large-image/layout-two'
import Heading from '../../../../shared-ui/components/ui/heading'
import PricingTable from '../../../../shared-ui/components/ui/pricing-table/layout-two'
import Text from '../../../../shared-ui/components/ui/text'
import Button from '../../../../shared-ui/components/ui/button'
import BoxIcon from '../../../../shared-ui/components/box-icon/layout-two'
import { ServiceWrap, FeaturedServicesWrap } from './index.style'
import { IntroWrap, IntroHeading, IntroText, PricingWrap, LocationsWrap, LocationBoxWrap, SolutionImageBoxWrap } from './index.style'
import CTASection from '../../components/cta-section'
import ContactFormSection from '../../components/contact-form-section'
import FrequentlyAskedQuestionsSection from '../../components/frequently-asked-questions-section'
import Image from '../../../../shared-ui/components/image'
import {device} from '../../../../shared-ui/theme'
import Img from "gatsby-image"
import styled from 'styled-components'
import Blog from '../../../../shared-ui/components/blog/layout-one'

export const BlogWrapper = styled.section `
    padding-top: 100px;
    padding-bottom: 70px;
    @media ${device.medium}{
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 10px;
    }
`;

export const LeftBox = styled.div `
    margin-top: 30px;
    margin-bottom: 30px;
    @media ${device.medium}{
        margin-top: 0;
        margin-bottom: 40px;
    }
    @media ${device.small}{
        margin-bottom: 46px;
    }
`;

export const RecentBlogWrap = styled.div `
    margin-top: 30px;
`;

export const FeaturedBlogWrap = styled.div `
    @media ${device.small}{
        margin-bottom: 50px;
    }
`;


const SolutionPage = ({location, data, ...props }) => {  
    
  const bannerImage = data.bannerImage;
  const primaryLocation = data.primaryLocation;
  const solutionPage = data.solutionPage;

  const aboutSectionTitleStyle = {
    mb: '60px',
    responsive: {
        small: {
            mb: '47px'
        }
    }
  };


  const aboutHeadingStyle = {
    layout: 'highlight',
    mr: '48px'
};
const aboutSubheadingStyle = {
    as: 'h6',
    mt: '30px',
    mb: '15px'
};
const aboutDescStyle = {
    fontSize: '18px',
    mb: '20px'
};
  return (    
    <InnerPageLayout location={location} 
        bannerImg={bannerImage} 
        browserTitle={solutionPage.pageBrowserTitle}
        pageData={{ title : solutionPage.pageName, tagline : "Una oficina sin preocupaciones."}}> 

        {solutionPage.introTitle && 
        <IntroWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            {...aboutSectionTitleStyle}                            
                            title={solutionPage.introTitle}                           
                        />
                    </Col>
                </Row>
                <Row>
                    {/*<Col lg={{ span: 4, offset: 1 }}>
                        <IntroHeading>
                           <Heading {...aboutHeadingStyle} ><mark>6</mark> AÑOS TRABAJANDO PARA NUESTROS CLIENTES EN CHIHUAHUA</Heading>
                           <Heading {...aboutSubheadingStyle} >Empresa orgullasemente Chihuahuense.</Heading>
                        </IntroHeading>
                    </Col>*/}
                    <Col lg={4}>
                        <SolutionImageBoxWrap>
                            <Img fluid={solutionPage.boxImage.fluid} alt="Solución" align="left" />
                        </SolutionImageBoxWrap>
                    </Col> 
                    <Col lg={8}>
                        <IntroText>
                            <Text {...aboutDescStyle} dangerouslySetInnerHTML={{
                                                    __html: solutionPage.introBody.childMarkdownRemark.html,
                                                }} />
                              
                            {/*path && <Button to={path} icon="far fa-long-arrow-right" {...aboutBtnStyle}>Conocenos</Button>*/}
                        </IntroText>
                    </Col>
                </Row>
            </Container>
        </IntroWrap>}

    {solutionPage.locations &&
        <LocationsWrap>
            <Container>
            <Row>
                    <Col lg={12}>
                        <SectionTitle
                            mb="40px"
                            title="Nos ubicamos en Chihuahua capital,<br/>en una de las <span>mejores zonas comerciales</span>."
                            subtitle="Nuestra Ubicación"
                        />
                    </Col>
                </Row>
            <Row>                                
                <Col lg={6} pr="3rem">
                    <Text {...aboutSubheadingStyle}>
                        {solutionPage.locations[0].title} 
                    </Text>
                    <Text {...aboutDescStyle}>
                        {solutionPage.locations[0].summaryText}
                    </Text>  
                    <Button to={solutionPage.locations[0].scheduleVisitSalesLink} target="_blank">Agendar Visita</Button>     
                    <Button to={`/locations/${solutionPage.locations[0].urlCode}`} ml="20px" target="_blank">Ver Ubicación</Button>                 
                </Col>
            
                <Col lg={6}>
                    <LocationBoxWrap>
                        <Img fluid={solutionPage.locations[0].primaryPhoto.fluid} alt="Video preview" align="left" />
                    </LocationBoxWrap>
                </Col>                                
                </Row>
            </Container>
          </LocationsWrap>}                                                  

        {solutionPage.services && <FeaturedServicesWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            mb="40px"
                            title="Nuestra  <span>amplia gama de servicios</span><br/> le ayudará a mantenerlo enfocado."
                            subtitle="Servicios"
                        />
                    </Col>
                </Row>
                <Row>
                    {solutionPage.services.map((item, i) => (
                        <Col lg={4} md={6} key={`box-icon-2nd-${i}`}>
                            <BoxIcon
                                boxStyles={{ mt: "30px" }}
                                id={item.id}
                                title={item.title}
                                desc={item.description}
                                path={""}                           
                                //icon={item.iconCode}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </FeaturedServicesWrap>}

        {solutionPage.pricingOptions && <PricingWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            mb="50px"
                            title="Contamos con <span>diferentes planes y precios</span><br/> que se adaptan según el crecimiento de tu empresa."
                            subtitle="Planes y Precios"                
                        />
                    </Col>
                </Row>
                <Row>
                    {solutionPage.pricingOptions.map(pricing => (
                        <Col md={solutionPage.pricingOptions.length === 4 ? 6 : 12} xl={solutionPage.pricingOptions.length === 4 ? 3 : 6} key={pricing.id}>
                            <PricingTable
                                title={pricing.optionName}
                                //image={pricing.image}
                                price={Intl.NumberFormat('es-MX').format(pricing.basePrice)}
                                period={pricing.basePriceType}
                                //path={pricing.path}
                                features={pricing.keyHighlights.map(keyHighlight => { return { id : keyHighlight, text : keyHighlight }})}
                                isFeatured={pricing.isFeatured}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </PricingWrap>}
        {solutionPage.featuredBlogs && <BlogWrapper>
            <Container>
                <Row>
                    <Col lg={4}>
                        <LeftBox>
                            <SectionTitle
                                align="left"
                                title="Noticias"
                                subtitle="Blog y Noticias"
                            />
                            <RecentBlogWrap>
                                {/*<RecentPostList />*/}
                            </RecentBlogWrap>
                        </LeftBox>
                    </Col>
                    <Col lg={8}>
                        <Row>                            
                            {solutionPage.featuredBlogs.map(featuredBlog => (
                                <Col md={6} key={featuredBlog.id}>
                                    <FeaturedBlogWrap>                                        
                                       <Blog content={{ 
                                            fields : {
                                                slug : 'blog/' + featuredBlog.urlCode  
                                            },
                                            frontmatter : 
                                            {
                                                title : featuredBlog.title,  
                                                'featured_image' : featuredBlog.images[0],
                                                format : 'contentfulImageFixed'
                                            },
                                            excerpt : featuredBlog.summary.summary
                                           }} />
                                    </FeaturedBlogWrap>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </BlogWrapper>}
        <ContactFormSection />
        <CTASection /> 
        {solutionPage.frequentlyAskedQuestions 
            && <FrequentlyAskedQuestionsSection faqs={solutionPage.frequentlyAskedQuestions} />}
    </InnerPageLayout>)
}

SolutionPage.defaultProps = {
    aboutSectionTitleStyle: {
        mb: '60px',
        responsive: {
            small: {
                mb: '47px'
            }
        }
    },
    aboutHeadingStyle: {
        layout: 'highlight',
        mr: '48px'
    },
    aboutSubheadingStyle: {
        as: 'h6',
        mt: '30px'
    },
    aboutDescStyle: {
        fontSize: '18px',
        mb: '20px'
    },
    aboutBtnStyle: {
        varient: 'texted',
        icondistance: '4px'
    }
}
 
export default SolutionPage

export const pageQuery = graphql`
  query GetSolutionPageContent($urlCode: String!) {
    bannerImage : file(relativePath: { eq: "legacy_images/parallax-virtual-office2.jpg" }) {
        childImageSharp {
           gatsbyImageData(
                width: 1024
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
           )
        }
      }
    solutionPage : contentfulSolutionPage(urlCode : {eq : $urlCode}){
        id
        contentful_id
        pageName
        pageBrowserTitle
        solutionDescription
        pageBrowserTitle
        boxImage {            
            fluid(maxWidth: 368, quality: 100) {
                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                ...GatsbyContentfulFluid
            }
        } 
        pricingOptions {
            optionName
            basePrice
            basePriceType
            isCustomPrice
            keyHighlights
            isFeatured
        }
        services {
            title
            description
            iconCode
        }
        introTitle
        introBody {
            childMarkdownRemark {
                html
              }
        }
        frequentlyAskedQuestions {
            id
            question
            answer {
                childMarkdownRemark {
                    html
                  }
            }
        }
        locations {
            id
            name
            title
            summaryText
            urlCode
            primaryPhoto {
                fluid(maxWidth: 1900, quality: 100) {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyContentfulFluid
                }
                fixed(width: 368, height:230, quality: 100) {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyContentfulFixed
                }
            }
            scheduleVisitSalesLink
        }
        featuredBlogs {
            id
            title
            urlCode
            publicPublishedDate  
            summary {
              summary
              childMarkdownRemark {
                  html
              }
            } 
            images {
              fixed(width: 368, height:230, quality: 100) {
                  # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                  ...GatsbyContentfulFixed
              }
            } 
        }
    }
    primaryLocation : contentfulLocation(title: {eq: "Oficinas Cuatro Torres Georgia 3100"}){
        id
        contentful_id
        summaryText
        availableServices {
            id
            contentful_id
            title
            shortDescription
            iconCode
        }
    }      
  }
`
